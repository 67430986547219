import { gql } from '@apollo/client';

export const CREATE_SPACE = gql`
  mutation createSpace($oppId: String!) {
    createSpace(oppId: $oppId) {
      message
      groups {
        id
        stream_link
      }
    }
  }
`;
export const UPDATE_SPACE = gql`
  mutation updateSpace($spaceId: String!, $input: SpaceInput!, $groupId: String) {
    updateSpace(spaceId: $spaceId, input: $input,  groupId:  $groupId) {
      message
      stream_link
      groups {
        id
        stream_link
      }
    }
  }
`;
export const DELETE_SPACE = gql`
  mutation deleteSpace($spaceId: String) {
    deleteSpace(spaceId: $spaceId) {
      message
      groups {
        id
        stream_link
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost($input: PostInput!) {
    createPost(input: $input) {
      content
      media_url
    }
  }
`;

export const CREATE_POST_COMMENT = gql`
  mutation createPostComment($input: PostCommentInput!) {
    createPostComment(input: $input) {
      content
    }
  }
`;

export const CREATE_EMOJI_REACTION = gql`
  mutation createEmojiReaction($input: EmojiReactionInput!) {
    createEmojiReaction(input: $input) {
      id
      child_post_url
      emoji
    }
  }
`;

export const CREATE_SPACE_MEDIA = gql`
  mutation createSpaceMedia($input: [SpaceMediaInput!], $space_id: String!, $group_id: String) {
    createSpaceMedia(input: $input, space_id: $space_id, group_id: $group_id) {
      message
      spaceMedia {
        uid
        url
        name
      }
      groups {
        id
        spaceMedia {
          uid
          url
          name
        }
      }
    }
  }
`;

export const DELETE_SPACE_MEDIA = gql`
  mutation deleteSpaceMedia($mediaUid: String!) {
    deleteSpaceMedia(mediaUid: $mediaUid) {
      message
      spaceMedia {
        uid
        url
        name
      }
      groups {
        id
        spaceMedia {
          uid
          url
          name
        }
      }
    }
  }
`;
