import { gql } from '@apollo/client';


export const SAVE_SPACE_GROUP = gql`
  mutation saveSpaceGroup($name: String!, $spaceId: String!, $users: [String!], $groupId: String) {
    saveSpaceGroup(name: $name, spaceId: $spaceId, users: $users, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;
export const DELETE_SPACE_GROUP = gql`
  mutation deleteSpaceGroup($spaceId: String!, $groupId: String!) {
    deleteSpaceGroup(spaceId: $spaceId, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;


